<template>
  <div class="ListCheckbox">
    <div class="item" v-for="(item, index) of data" :key="index">
      <div class="round">
        <input
          type="checkbox"
          :checked="item.checked"
          @change="updateValue(item, $event.target.checked)"
          :id="index"
        />
        <label :for="index"></label>
      </div>
      <label :for="index">{{ item.name }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListCheckbox",
  props: { data: Array },
  data() {
    return {};
  },
  methods: {
    updateValue(item, check) {
      this.$emit("update", { item: item, value: check });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/checkbox.scss";

.ListCheckbox {
  height: 100%;
}

.item {
  display: flex;
  justify-content: left;
  align-items: center;
  > div {
    margin: 10px 6px;
  }
  border-bottom: 1px solid rgb(231, 231, 231);
}

.item:last-child {
  border-bottom: none;
}

// .item {
//   // margin: 10px 10px;
//   display: flex;
//   padding: 15px 14px;
//   // border-radius: 10px;
//   justify-content: space-between;
//   align-items: center;
//   // border: 1px solid #d8d8d8;
//   min-height: 45px;
//   user-select: none;
//   box-shadow: 0px 2px 1px -1px rgb(155 155 155 / 21%);
//   img {
//     width: 15px;
//   }
// }
</style>