<template>
  <div class="settingMain">
    <NavbarBack :title="productId ? 'แก้ไขสินค้า' : 'สร้างสินค้า'" />
    <div style="height: 40px"></div>
    <ValidationObserver ref="form">
      <form>
        <div class="group">
          <label>ชื่อสินค้า <span class="required">*</span></label>
          <ValidationProvider name="name" rules="required" v-slot="{ errors }">
            <input
              v-model="form.name"
              :readonly="notAgentProduct"
              type="text"
            />
            <div class="errorMsg">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <div class="group">
          <label>รหัสสินค้า (SKU) <span class="required">*</span></label>
          <ValidationProvider name="sku" rules="required" v-slot="{ errors }">
            <input
              v-model="form.sku"
              :readonly="notAgentProduct"
              type="text"
              required
              name="sku"
            />
            <div class="errorMsg">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
        <div v-bind:class="{ hide: notAgentProduct }" class="group">
          <label>รายละเอียดสินค้า <span class="required">*</span></label>
          <textarea
            v-model="form.description"
            class=""
            rows="8"
            name="description"
            required
          ></textarea>
        </div>
        <div v-bind:class="{ hide: notAgentProduct }" class="group">
          <label>ประเภทสินค้า <span class="required">*</span></label>
          <select v-model="form.productTypeId" required name="productTypeId">
            <option disabled :value="null">เลือกประเภทสินค้า:</option>
            <option
              :value="type.id"
              v-for="type of productTypes"
              :key="type.id"
            >
              {{ type.name }}
            </option>
          </select>
        </div>
        <div v-bind:class="{ hide: notAgentProduct }" class="group">
          <label>แบรนด์</label>
          <select v-model="form.brandId" name="brandId">
            <option :value="null" selected>ไม่ระบุ</option>
            <option :value="brand.id" v-for="brand of brands" :key="brand.id">
              {{ brand.name }}
            </option>
          </select>
        </div>
        <CategoryForm
          v-bind:class="{ hide: notAgentProduct }"
          ref="categoryForm"
          :catData="categoriesCheck"
        />
        <div
          v-bind:class="{ hide: notAgentProduct }"
          class="group"
          id="groupAlcohol"
        >
          <Checkbox v-model="form.alcohol" :id="'alcoholInput'" />
          <label class="alcoholInputLabel" for="alcoholInput"
            >สินค้ามีแอลกอฮอล์</label
          >
        </div>

        <div v-bind:class="{ hide: !notAgentProduct }" class="group flexGroup">
          <Checkbox
            v-model="form.refStandardPrice"
            :id="'refStandardPriceInput'"
          />
          <label class="standardPriceInputLabel" for="refStandardPriceInput"
            >ใช้ราคาสินค้ามาตรฐานไทยเบฟ</label
          >
        </div>

        <div class="group">
          <label>ข้อมูลการขาย</label>
          <div class="saleGroup">
            <div>
              <label>ราคาเต็ม <span class="required">*</span></label>
              <ValidationProvider
                name="price"
                rules="required|min_value:0"
                v-slot="{ errors }"
              >
                <input
                  type="number"
                  :disabled="form.refStandardPrice"
                  step=".01"
                  class="priceInput"
                  required
                  name="price"
                  v-model.number="form.price"
                />
                <div class="errorMsg">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
            <div>
              <label>ราคาขาย <span class="required">*</span></label>
              <ValidationProvider
                name="salePrice"
                rules="required|min_value:0"
                v-slot="{ errors }"
              >
                <input
                  type="number"
                  :disabled="form.refStandardPrice"
                  step=".01"
                  class="priceInput"
                  required
                  name="salePrice"
                  v-model.number="form.salePrice"
                />
                <div class="errorMsg">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
            <div>
              <label>หน่วย <span class="required">*</span></label>
              <ValidationProvider
                name="unit"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  v-model="form.unit"
                  :disabled="form.refStandardPrice || notAgentProduct"
                  type="text"
                  class="priceInput"
                  required
                />
                <div class="errorMsg">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <StepPriceForm :readonly="form.refStandardPrice" ref="stepPriceForm" />

        <div class="group">
          <label>สถานะสินค้า <span class="required">*</span></label>
          <ValidationProvider
            name="product_status"
            rules="required"
            v-slot="{ errors }"
          >
            <select v-model="form.product_status" required>
              <option disabled :value="null">เลือกสถานะสินค้า:</option>
              <option
                :value="status"
                v-for="(status, index) of listProductStatusData"
                :key="index"
              >
                {{ status }}
              </option>
            </select>
            <div class="errorMsg">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <div class="group flexGroup">
          <Checkbox v-model="form.addons" :id="'promotionInput'" />
          <label class="promotionInputLabel" for="promotionInput"
            >สินค้าโปรโมชั่น</label
          >
        </div>
        <div v-if="form.addons" class="group">
          <label>รายละเอียดโปรโมชั่น</label>
          <textarea
            v-model="form.addonsDetail"
            class=""
            rows="2"
            name="addonsDetail"
          ></textarea>
        </div>

        <UploadProductImage
          v-bind:class="{ hide: notAgentProduct }"
          ref="uploadImage"
          :name="'productImage'"
        />
        <div class="group" v-if="$isSeller">
          <div
            v-if="!productId"
            @click="createProduct()"
            class="btn comfirmBtn"
          >
            สร้างสินค้า
          </div>
          <div
            v-if="productId"
            @click="createProduct(true)"
            class="btn comfirmBtn"
          >
            บันทึกข้อมูลสินค้า
          </div>
        </div>
        <div style="height: 40px"></div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import NavbarBack from "@/components/NavbarBack";
import { GET, POST, PUT } from "@/utils/http";
import { listProductStatus } from "@/utils/product";
import UploadProductImage from "./components/UploadProductImage";
import CategoryForm from "./components/CategoryForm.vue";
import Checkbox from "@/components/Checkbox";
import StepPriceForm from "./components/StepPriceForm.vue";

import { extend } from "vee-validate";
import { required, min_value } from "vee-validate/dist/rules";

// Override the default message.
extend("required", {
  ...required,
  message: "กรุณากรอกข้อมูลให้ถูกต้อง",
});

extend("min_value", {
  ...min_value,
  params: ["min"],
  message: "ค่าต้องไม่น้อยกว่า {min}",
});

export default {
  props: { productId: String },
  components: {
    NavbarBack,
    UploadProductImage,
    CategoryForm,
    Checkbox,
    StepPriceForm,
  },
  data() {
    return {
      form: {
        name: "",
        sku: "",
        alcohol: false,
        brandId: null,
        price: 0,
        unit: "",
        salePrice: 0,
        description: "",
        addons: false,
        addonsDetail: "",
        refStandardPrice: null,
        productTypeId: null,
        product_status: null,
      },
      paymentTypes: [],
      productTypes: [],
      categories: [],
      categoriesCheck: [],
      brands: [],
      product: {},
      listProductStatusData: listProductStatus().map((e) => e.name),
    };
  },
  computed: {
    notAgentProduct() {
      return this.product.parentId != null;
    },
    listToggle() {
      return this.paymentTypes.map((e) => {
        return { ...e, checked: e.shopPaymentType.enabled };
      });
    },
  },
  methods: {
    categoryClick(item) {
      this.$router.push({
        path: `/settings/receive-payment/${item.path}`,
      });
    },
    async getProductTypes() {
      const { status, body } = await GET(`/api/product-types`);
      if (status !== 200) {
        throw "get product-types: not ok";
      }
      this.productTypes = body.data;
    },
    async getCategories() {
      const { status, body } = await GET(`/api/categories`);
      if (status !== 200) {
        throw "get categories: not ok";
      }
      this.categories = body.data;
      this.categoriesCheck = this.categories;
    },
    async getBrands() {
      const { status, body } = await GET(`/api/brands`);
      if (status !== 200) {
        throw "get brands: not ok";
      }
      this.brands = body.data;
    },
    async postProduct(reqData) {
      try {
        const { status, body } = await POST(
          `/api/shops/${this.$myShop.id}/products`,
          reqData
        );

        if (status !== 201) {
          throw "post product: not ok";
        }

        return body;
      } catch (err) {
        console.error(err);
      }
    },
    async putProduct(reqData) {
      try {
        const { status, body } = await PUT(
          `/api/products/${this.productId}`,
          reqData
        );

        if (status !== 200) {
          throw "put product: not ok";
        }

        return body;
      } catch (err) {
        console.error(err);
      }
    },
    async createProduct() {
      const formSuccess = await this.$refs.form.validate();
      const isEdit = this.productId;
      const stepPriceForm = this.$refs.stepPriceForm;

      if (!formSuccess || stepPriceForm.checkError()) {
        window.$alert("กรุณากรอกข้อมูลให้ครบและถูกต้อง");
        return;
      }

      if (!(await window.$alert("ต้องการบันทึกสินค้าหรือไม่?", "confirm"))) {
        return;
      }

      let reqData = this.form;
      reqData["categoryId"] = this.$refs.categoryForm.selectedCatsId;
      reqData.stepPrices = {
        updates: stepPriceForm.steps,
        deletes: stepPriceForm.deletes,
      };

      const productCreatedData = isEdit
        ? await this.putProduct(reqData)
        : await this.postProduct(reqData);

      if (!productCreatedData?.id) {
        await window.$alert("ขออภัย บันทึกสินค้าไม่สำเร็จ กรุณาลองใหม่ภายหลัง");
        this.$router.back();
        return;
      }

      const uploaded = await this.uploadProductImage(productCreatedData.id);

      window.loading(false);

      if (productCreatedData?.id && uploaded) {
        await window.$alert("บันทึกสินค้าสำเร็จ");
        this.$router.replace("/manage-product");
        return;
      }

      if (productCreatedData?.id && !uploaded) {
        await window.$alert(
          "บันทึกสินค้าสำเร็จ และพบข้อผิดพลาดการบันทึกรูปภาพ กรุณาแก้ไขรูปภาพสินค้าในภายหลัง"
        );
        this.$router.back();
        return;
      }
    },
    async uploadProductImage(productId) {
      try {
        window.loading(true);
        const newImgs = this.$refs.uploadImage.selectedImgs.filter(
          (e) => !e.id
        );
        const formData = new FormData();
        if (newImgs.length > 0) {
          newImgs.forEach((data) => {
            formData.append("photos", data.result.file, data.result.fileName);
          });

          const { status } = await POST(
            `/api/products/${productId}/photos`,
            formData
          );

          if (status !== 201) {
            throw "post product images: not ok";
          }
        }

        return true;
      } catch (err) {
        console.error(err);
      }
    },
    async handleFetch(fn) {
      try {
        window.loading(true);
        await fn;
      } catch (err) {
        console.error(err);
        await window.$alert("พบข้อผิดพลาดการขอข้อมูล");
        this.$router.back();
      } finally {
        window.loading(false);
      }
    },
    async getProduct(productId) {
      const { status, body } = await GET(`/api/products/${productId}`);

      if (status !== 200) {
        throw "prroduct not ok";
      }
      this.product = body;

      // set old data
      Object.keys(this.form).forEach(
        (k) => (this.form[k] = this.product[k] ?? this.form[k])
      );

      // set old cat
      this.categoriesCheck = this.categories.map((c) => {
        if (this.product.categories.some((pc) => pc.id === c.id)) {
          return { ...c, checked: true };
        }
        return c;
      });
      this.$refs.categoryForm.resultData = this.product.categories;

      // set old photos
      this.$refs.uploadImage.selectedImgs = this.product.productPhotos;

      // set old step price
      this.$refs.stepPriceForm.steps =
        this.product.productStepPrices.map((s) => {
          return {
            productStepPriceId: s.id,
            min: s.min,
            max: s.max,
            price: s.price,
          };
        }) || [];
    },
  },
  async mounted() {
    // this.getPaymentTypes();
    document.querySelector("body").scrollTo(0, 0);
    window.loading(true);
    try {
      await Promise.all([
        this.getProductTypes(),
        this.getCategories(),
        this.getBrands(),
      ]);

      // is edit mode
      if (this.productId) {
        await this.getProduct(this.productId);
      }
    } catch (err) {
      console.error(err);
      await window.$alert("พบข้อผิดพลาดการขอข้อมูล");
      this.$router.back();
    } finally {
      window.loading(false);
      // this.loading = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.settingMain {
  // padding-top: 60px;
  background: #fff;
  height: 100%;
  > label {
    font-size: 15px;
    // padding-left: 18px;
    margin: 18px;
    display: block;
  }
  .group {
    padding: 15px;
    > label {
      margin-bottom: 10px;
      display: block;
    }
    .required {
      color: red;
    }
  }
}

textarea:invalid,
input:invalid,
select:invalid {
  border-color: red;
}

.saleGroup {
  label {
    margin: 14px 0 4px 0;
    display: block;
    font-size: 15px;
    margin-left: 7px;
  }

  input {
    text-align: center;
  }
}

input {
  min-width: 120px;
}

.errorMsg {
  margin: 10px;
  color: crimson;
}

.priceInput {
  width: 70%;
}

.listToggle {
  padding: 10px;
  label {
    padding-left: 8px;
  }
}

.listToggleSwitch {
  height: auto;
}

select {
  text-indent: 5px;
  line-height: 25px;
}

select,
input,
textarea {
  padding: 10px 8px;
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 8px;
  min-height: 45px;
  color: rgb(63, 63, 63);
  display: block;
  margin: 0 auto;
}

input:read-only {
  background-color: #eaeaea;
}

.comfirmBtn {
  margin: 30px auto 20px auto;
  background: var(--primary-color);
  color: #fff;
}

#groupAlcohol,
.flexGroup {
  display: flex;
}

.alcoholInputLabel,
.standardPriceInputLabel,
.promotionInputLabel {
  display: inline-block !important;
  margin-left: 10px;
}

.hide {
  display: none !important;
}
</style>