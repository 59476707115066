<template>
  <div class="group">
    <label>หมวดหมู่</label>
    <div class="selectedList">
      <div class="selectedItem" v-for="result of resultData" :key="result.id">
        {{ result.name }}
      </div>
    </div>
    <div @click="selectModal = true" class="btn addCatBtn">แก้ไขหมวดหมู่</div>

    <Modal :open="selectModal" :closeIcon="false" class="modal">
      <div class="modalBody">
        <ListCheckbox :data="catData" @update="checkBoxUpdate" />
        <div class="btn closeBtn" @click="selectModal = false">ปิด</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import ListCheckbox from "@/components/ListCheckbox";

export default {
  components: { Modal, ListCheckbox },
  props: { catData: Array },
  data() {
    return {
      selectModal: false,
      resultData: [],
    };
  },
  computed: {
    selectedCatsId() {
      return this.resultData.map((e) => e.id);
    },
  },
  methods: {
    checkBoxUpdate(result) {
      if (!result.value) {
        this.resultData = this.resultData.filter(
          (item) => item.id !== result.item.id
        );
        return;
      }

      if (result.value) {
        const exits = this.resultData.find((catId) => catId === result.item.id);
        if (!exits) {
          this.resultData.push(result.item);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/checkbox.scss";

select,
input,
textarea {
  padding: 10px 8px;
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 8px;
  min-height: 45px;
  color: rgb(63, 63, 63);
  display: block;
  margin: 0 auto;
}

.wrapCategory {
  padding-top: 10px;
}

.group {
  padding: 15px;
  > label {
    margin-bottom: 10px;
    display: block;
  }
  .required {
    color: red;
  }
}

.addCatBtn {
  margin: 10px auto 10px auto;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(187, 187, 187);
}

.closeBtn {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(187, 187, 187);
}

.modalBody {
  width: 80vw;
  max-width: 400px;
}

.selectedList {
  display: flex;
  flex-wrap: wrap;
}

.selectedItem {
  background: rgb(231, 231, 231);
  border-radius: 10px;
  padding: 5px 6px;
  margin: 0 5px 4px 0;
}
</style>