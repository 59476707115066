<template>
  <div class="group">
    <label>ราคาขายส่ง</label>
    <div class="selectedList">
      <div class="stepItem" v-for="(step, index) of steps" :key="index">
        <div class="head">
          <label>ช่วงราคาที่ {{ index + 1 }}</label>
          <span
            v-show="!readonly"
            class="close"
            @click.stop="removeStep(index)"
          >
            <img width="18" src="/x-circle.svg" />
          </span>
        </div>

        <div class="inputValue">
          <div>
            <label>ขั้นต่ำ</label>
            <input
              type="number"
              v-model.lazy.number="step.min"
              :disabled="index != 0 || readonly"
            />
          </div>
          <div>
            <label>สูงสุด</label>
            <input
              type="number"
              :disabled="readonly"
              v-model.lazy.number="step.max"
            />
          </div>
          <div>
            <label>ราคา</label>
            <input
              type="number"
              :disabled="readonly"
              step=".05"
              v-model.lazy.number="step.price"
            />
          </div>
        </div>
        <small
          v-for="(error, index) of step.errors"
          :key="index"
          class="errorMessage"
          >- {{ error }}</small
        >
      </div>
      <!-- <small v-if="steps.length > 0">
        * หากต้องการค่าสูงสุดไม่จำกัดให้เว้นเป็นค่าว่าง
      </small> -->
    </div>
    <div
      @click="!readonly && addStep()"
      v-bind:class="[{ disabled: readonly }, 'btn addCatBtn']"
      class="btn addCatBtn"
    >
      เพิ่มช่วงราคา
    </div>
  </div>
</template>

<script>
export default {
  props: {
    catData: Array,
    readonly: Boolean,
  },
  data() {
    return {
      selectModal: false,
      steps: [],
      deletes: [],
    };
  },
  watch: {
    steps: {
      deep: true,
      handler() {
        this.steps.forEach((s, i) => {
          s.errors = [];

          const notNumber = [s.price, s.min, s.max].some(
            (e) => e === "" || !/^-?\d*(\.\d+)?$/.test(e)
          );
          if (notNumber) s.errors.push("ค่าต้องเป็นตัวเลข");

          if ([s.min, s.max].some((e) => !Number.isInteger(e)))
            s.errors.push("ค่า ขั้นต่ำ, สูงสุด ต้องเป็นจำนวนเต็ม");

          if (s.price < 0) s.errors.push("ค่าต่ำสุดคือ 0");
          if (s.min <= 1) s.errors.push("ค่าขั้นต่ำต้องมากกว่า 1"); //s.min = 2;

          if (s.max === "") s.max = null;
          if (i !== 0) {
            s.min = this.steps[i - 1].max + 1;
          }
          if (s.max < s.min) {
            if (
              (i === this.steps.length - 1 && s.max !== null) ||
              i !== this.steps.length - 1
            ) {
              s.errors.push("ค่าสูงสุดต้องไม่น้อยกว่าค่าน้อยสุด");
              // s.max = s.min;
            }
          }
        });
      },
    },
  },
  methods: {
    checkError() {
      return this.steps.some((s) => s.errors.length > 0);
    },
    removeStep(stepIndex) {
      if (this.steps[stepIndex].productStepPriceId) {
        this.deletes.push(this.steps[stepIndex].productStepPriceId);
      }
      this.steps.splice(stepIndex, 1);
    },
    addStep() {
      const lastMin =
        this.steps.length > 0 ? this.steps[this.steps.length - 1].max + 1 : 2;
      this.steps.push({
        min: lastMin,
        max: lastMin,
        price: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/checkbox.scss";

select,
input,
textarea {
  padding: 10px 8px;
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 8px;
  min-height: 45px;
  color: rgb(63, 63, 63);
  display: block;
  margin: 0 auto;
}

.wrapCategory {
  padding-top: 10px;
}

.group {
  padding: 15px;
  > label {
    margin-bottom: 10px;
    display: block;
  }
  .required {
    color: red;
  }
}

.addCatBtn {
  margin: 10px auto 10px auto;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(187, 187, 187);

  &.disabled {
    background: #eaeaea;
    color: #acacac;
  }
}

.closeBtn {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(187, 187, 187);
}

.modalBody {
  width: 80vw;
  max-width: 400px;
}

.selectedList {
  display: flex;
  flex-wrap: wrap;
}

.stepItem {
  font-size: 14px;
  margin: 10px auto;
  .head {
    display: flex;
    justify-content: space-between;
    > label {
      font-weight: bold;
      margin-left: 5px;
    }
  }
  .errorMessage {
    color: crimson;
    display: block;
    margin: 4px 0;
  }
}

.inputValue {
  display: flex;
  div {
    display: block;
    max-width: 400px;
    margin: 5px;
    text-align: center;
    > label {
      display: block;
      margin: 5px;
    }
    input {
      text-align: right;
      height: 30px;
      min-height: 30px;
      :invalid,
      .invalid {
        border-color: red;
      }
    }
  }
}
</style>